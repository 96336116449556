<template>
  <b-navbar toggleable="lg"  variant="info">
     <b-navbar-brand href="#">Virtual Lab</b-navbar-brand>

     <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

     <b-collapse id="nav-collapse" is-nav>
       <b-navbar-nav>

         <Help />
       </b-navbar-nav>

       <!-- Right aligned nav items -->
       <b-collapse id="navbar-toggle-collapse" is-nav>
    </b-collapse>

       <b-navbar-nav class="ml-auto">
          <b-nav-item v-on:click="runSimulation" href="#" style="background-color: white;">RUN</b-nav-item>
       </b-navbar-nav>
     </b-collapse>
   </b-navbar>
</template>

<script >


import Help from './Help.vue';
export default {
  name: 'app',
  components: {
    Help
  },
  data() {
    return {}
  },
  methods: {
    runSimulation: function (){
      console.log ("navbar > runSimulation to true");
      this.$store.commit('runSimulation',true);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
