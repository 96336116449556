import Vue from 'vue'
import App from './App.vue'
import 'es6-promise/auto';
import Vuex from 'vuex'
  //import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
import VueKonva from 'vue-konva';

import {
  BootstrapVue, IconsPlugin
}
from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
//import { EventDispatcher } from './components/globals.js';

//curl "https://peaceful-hamlet-05175.herokuapp.com/socket.io/?EIO=4&transport=polling"


Vue.config.productionTip = false
  //Add unimported components to ignore list to prevent warnings.
Vue.config.ignoredElements = ['field', 'block', 'category', 'xml', 'mutation',
  'value', 'sep'
]

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
  // Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(Vuex)

const assigment_config_active = 0;
const assignment_config = [{
  "choiceType": "VanNaar",
  "title": "Van Hilversum naar Amsterdam",
  "description": "Laat de trein vanzelf rijden van Hilversum naar Amsterdam door de juiste hardware te koppelen en de juiste programmacode te schrijven.",
  "behaviour": {
    "enableChat": true,
    "enableTeam": true
  }
}, {
  "choiceType": "Tussenstop",
  "title": "Stop bij Bussum",
  "description": "Laat de trein vanzelf rijden van Hilversum naar Amsterdam maar stop ook even in Bussum.",
  "behaviour": {
    "enableChat": true,
    "enableTeam": true
  }
}, {
  "choiceType": "Koe",
  "title": "Pas op voor de koe!",
  "description": "Laat de trein vanzelf rijden maar pas op voor loslopende koeien.",
  "behaviour": {
    "enableChat": true,
    "enableTeam": true
  }
}]

const params = assignment_config[assigment_config_active];

const options = {
  path: "/"
}; //Options object to pass into SocketIO
/*
Vue.use(new VueSocketIO({
  debug: true,
  connection: 'https://peaceful-hamlet-05175.herokuapp.com/ws',
  //connection: SocketIO('http://localhost:3001', options),
  vuex: {
    store,
    actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_'
  }

}))
*/
/*
Vue.use(new VueSocketIO({
  debug: true,
  connection: 'https://peaceful-hamlet-05175.herokuapp.com',
  vuex: {
    store,
    actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_'
  },
  options: {
    path: "/",
  }
}))
*/

/*
const socket = SocketIO("https://peaceful-hamlet-05175.herokuapp.com", {
  withCredentials: true,
  transportOptions: {
    polling: {
      extraHeaders: {
        "my-custom-header": "abcd"
      }
    }
  }
});
*/

const store = new Vuex.Store({

    state: {
      isConnected: false,
      runSimulation: false,
      socketMessages: '',
      code: "",
      count: 0,
      params: params
    },


    mutations: {
      code(state, n) {
          state.code = n
        },
        runSimulation(state, n) {
          state.runSimulation = n
        },
        SOCKET_CONNECT(state) {
          state.isConnected = true;
        },

        SOCKET_DISCONNECT(state) {
          state.isConnected = false;
        },

        SOCKET_MESSAGECHANNEL(state, message) {
          state.socketMessage = message
        }

    }
  })
  //curl "https://peaceful-hamlet-05175.herokuapp.com/socket.io/?EIO=4&transport=polling"
  //Vue.use(BootstrapVue)
  //Vue.use(IconsPlugin)


Vue.use(VueKonva);

new Vue({
  store: store,
  components: {
    App
  },
  render: h => h(App),
}).$mount(".h5p-train")
