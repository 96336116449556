<template>
 <div class ="TeamMembersListPanel">






        <div class="">
          <h4>Team</h4>
          <ul class="">
            <li v-for="item in items" :key="item.id">
              <b-row class="mt-2">
              <b-col sm="2">
                <label for="textarea-large">    {{ item.name }}</label>
              </b-col>
              <b-col sm="10">
                <b-form-checkbox
        v-for="option in options"
        v-model="item.authorizations_selected"
        :key="option.value"
        :value="option.value"
        
        name="flavour-3a"
      >
        {{ option.text }}
      </b-form-checkbox>
              </b-col>
            </b-row>


            </li>
          </ul>


    </div>
  </div>
</template>

<script>

export default {
    props : ['items'],
    data() {
      return {

        options: [
          { text: 'Edit hardware configurator', value: 'HWCONFIG_EDIT' },
          { text: 'Edit programming editor', value: 'PROGEDIT_EDIT' },
          { text: 'start simulation using RUN button', value: 'ACTION_RUN' },


        ]
      }
    }
 }
</script>

<style>
.TeamMembersListPanel { text-align: left;}
ul.timeline {
    list-style-type: none;
    position: relative;
}
ul.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}
ul.timeline > li {
    margin: 20px 0;
    padding-left: 20px;
}
ul.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22c0e8;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
}
</style>
