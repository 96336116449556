
<template>

<div id="app" style="position:absolute; top:0;bottom:0;left:0;right:0">
  <div style="display:absolute; top:0">
  <Navbar />
</div>
<div style="display:absolute; top:150; height:80%">
  <b-tabs content-class="mt-3">
    <b-tab title="Ontwerp" active>
       <b-card-text>
      <Design />
    </b-card-text>
    </b-tab>
    <b-tab title="Bouw" class="tab_bouw">
      <b-row align-v="stretch">
    <b-col>
      <div class="card">
    <Demo />
    </div>
  </b-col>

    <b-col><div class="card" >
    <Timeline />
    <Chat />
    </div></b-col>

  </b-row>
  <b-row align-v="stretch">
    <b-col><div class="card">
    <Configurator />
    </div></b-col>
    <b-col><div class="mycard" >
    <Blockly />
    </div></b-col>

  </b-row>


    </b-tab>
    <b-tab title="Team" active>
       <b-card-text>
      <Team />
    </b-card-text>
    </b-tab>
  </b-tabs>

</div>
</div>
</template>

<script>
import Configurator from './components/Configurator.vue';
//import Editor from '../components/Editor.vue';
import Timeline from './components/Timeline.vue';
import Blockly from './components/Blockly.vue';
import Demo from './components/Demo.vue';
import Chat from './components/Chat.vue';
import Team from './components/Team.vue';
import Design from './components/Design.vue';
import Navbar from './components/Navbar.vue';
export default {
  name: 'app',
  components: {
    Blockly,
    Demo,
    Timeline,
    Configurator,
    Chat,
    Design,
    Navbar,
    Team
  },
  data(){
    return {
      configStage: {
        width:800,
        height:800
      },
      card: {
        width: 400,
        height:400
      },
      code: '',
      options: {
        media: 'media/'
      }
    }
  },
  
  }

</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width:100%;
  height:100%;
}

.tab_bouw .card,
.tab_bouw .mycard
 {
  float:left;
  width:100%;
  min-width:400px;
  height:400px;
  position: relative;
  float:left;
  display:block;
}


</style>
