<template>
<div style="padding:10px; width:45%; height:100%; overflow:auto; position: absolute;
top: 0;
right: 0;">
<ChatList :items="items" />
</div>
</template>

<script >

import ChatList from '../components/ChatList.vue'


export default {
  components: {
    ChatList
  },

  data() {
    return {
items:[{
id: 1,
      name: 'Jasper',
      message: 'Zijn jullie begonnen?',
      date: '6 aug 2021',
      time: '12:34'
      },
      {
id: 2,
      name: 'Tim',
      message: 'Bijna, ik lees de help nog even',
      date: '6 aug 2021',
      time: '12:36'
      }]
  }
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#form { background: rgba(0, 0, 0, 0.15); padding: 0.25rem; position: fixed; bottom: 0; left: 0; right: 0; display: flex; height: 3rem; box-sizing: border-box; backdrop-filter: blur(10px); }
#input { border: none; padding: 0 1rem; flex-grow: 1; border-radius: 2rem; margin: 0.25rem; }
#input:focus { outline: none; }
#form > button { background: #333; border: none; padding: 0 1rem; margin: 0.25rem; border-radius: 3px; outline: none; color: #fff; }

#messages { list-style-type: none; margin: 0; padding: 0; }
#messages > li { padding: 0.5rem 1rem; }
#messages > li:nth-child(odd) { background: #efefef; }

</style>
