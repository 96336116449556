<template>
<div>

<b-nav-item v-on:click="greet" href="#">Help</b-nav-item>

<sweet-modal ref="helpmodal">
  <div class="helpmodal">
    <h4>Help</h4>
    <h5>Algemeen</h5>
    <ul>
    <li><a href="https://content.edo-trein.arhc.informatica.gsf.nl/content/Introductie moodle.mp4" target="_blank" >Bekijk de video over Moodle</a></li>
    <li><a href="https://content.edo-trein.arhc.informatica.gsf.nl/content/Introductie%20dev%20omgeving.mp4 " target="_blank">Bekijk de video over de ontwikkelomgeving</a></li>
  </ul>
    <h5>De opdracht</h5>
    <ul>
      <li><a href="https://content.edo-trein.arhc.informatica.gsf.nl/content/help/work-in-progress.html" target="_blank"> Lees de uitgebreide uitleg bij de opdracht</a></li>
  </ul>
  <h5>De ontwikkelomgeving</h5>
  <ul>
    <li><a href="https://content.edo-trein.arhc.informatica.gsf.nl/content/help/work-in-progress.html" target="_blank">Gebruik van blockly editor</a></li>
    <li><a href="https://content.edo-trein.arhc.informatica.gsf.nl/content/help/work-in-progress.html" target="_blank">Over programmeren</a></li>
    <li><a href="https://content.edo-trein.arhc.informatica.gsf.nl/content/help/work-in-progress.html" target="_blank">Gebruik van hardware configurator</a></li>
    <li><a href="https://content.edo-trein.arhc.informatica.gsf.nl/content/help/work-in-progress.html" target="_blank">Over de verschillende hardware componenten</a></li>
  </ul>
    <h5>Ga op zoek</h5>
  <ul>
    <li><a href="https://content.edo-trein.arhc.informatica.gsf.nl/content/help/work-in-progress.html" target="_blank">Stel een vraag via chat in de opdracht, via chat in Moodle</a></li>
    <li><a href="https://content.edo-trein.arhc.informatica.gsf.nl/content/help/work-in-progress.html" target="_blank">Stel je vraag tijdens de les </a></li>
    <li><a href="https://content.edo-trein.arhc.informatica.gsf.nl/content/help/work-in-progress.html" target="_blank">Mail je docent</a></li>
    <li><a href="https://content.edo-trein.arhc.informatica.gsf.nl/content/help/work-in-progress.html" target="_blank">Zoek op Internet</a></li>
  </ul>
  </div>
</sweet-modal>

</div>
</template>

<script >
import { SweetModal  } from 'sweet-modal-vue'

export default {
  components: {
SweetModal

},

  data() {
    return {
items:[{
id: 1,
      name: 'Jasper',
      selected: false
      },
      {
id: 2,
      name: 'Tim',
      selected: false
    }]
  }
},
methods: {
    greet: function () {
      this.$refs.helpmodal.open();
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.helpmodal {text-align:left}
</style>
