<template>
<div class="demoContainer" ref="demoContainer" >

    <v-stage
      ref="stage"
      :config="configStage"
      >
      <v-layer ref="layer">
        <v-rect ref="ground" :config="{
          x:0,
          y:configStage.height-50,
          height:50,
          width: configStage.width,
          fill: 'green'
        }" ></v-rect>
        <Rails :xpos="0"  :ypos="this.configStage.height-50" :width="this.configStage.width" />

        </v-layer>
      <v-layer ref="layer">
      <City :xpos="5" :ypos="this.configStage.height-150" title="Hilversum" />
      <City v-if="isTypeTussenstop"  :xpos="this.configStage.width*0.3" :ypos="this.configStage.height-150" title="Bussum" />
      <City :xpos="this.configStage.width-80" :ypos="this.configStage.height-150" title="Amsterdam" />
      </v-layer>
      <v-layer ref="layer">
        <v-image ref="trein" :config="{
            image: trainImage,
            x: 10,
            y: configStage.height-130,
            height: 100,
            width: 100
        }" >
        </v-image>
        <v-image v-if="isTypeKoe" ref="cow" :config="{
          x:300,
          y:configStage.height-100,
          height:60,
          width:60,
          image: imageCow

        }" ></v-image>
      </v-layer>

    </v-stage>

  </div>
</template>

<script >
// { Component, Prop, Vue } from 'vue-property-decorator';
// eslint-disable-next-line no-unused-vars
import Konva from "konva";
import City from "./demo/City.vue"
import Rails from "./demo/Rails.vue"

const width = 30;
const height = 130;

export default {
  components: {
    City,
    Rails
  },
  data() {
    return {
      configStage:  {
        width: width,
        height: height,
      },
      trainImage:null,
      imageCow: null,
      image: null,
      list: [

      ],
      dragItemId: null,
      configKonva: {
      },
      isTypeVanNaar: true,
      isTypeKoe: true,
      isTypeTussenstop: true

    };
  },
  computed: {
    count () {
      console.log ('compute count:'+ this.$store.state.count);
      return this.$store.state.count;
    },
    params () {
      console.log ('compute config:'+ this.$store.state.params);
      return this.$store.state.params;
    },
    code () {
      console.log ('compute code:'+ this.$store.state.code);
      return this.$store.state.code;
    },
    runSimulation () {
      console.log ('demo > computed code: '+ this.$store.state.runSimulation);
      return this.$store.state.runSimulation;
    }

  },
  watch:{
    runSimulation(value) {
       console.log(`My store value for 'code' changed to ${value}`);
           this.$store.commit('runSimulation',false);
       // example of Konva.Animation
       //    const period = 8000;
       const train = this.$refs.trein.getNode();
       //    const amplitude = this.configStage.width - 4* train.getWidth();
       //    const centerX = this.configStage.width / 2;

       var Station, Engine_torque, Breaking_torque, State, Start_button;

       // Describe this function...
         Engine_torque = 0;
         Breaking_torque = 1;
         State = 1;
         Station = "Hilversum";

         var period = 8000;
         var deltaX = 5;
         var currentX = 0;
         var centerX = 0;
         var maxX = 600; //this.configStage.width;
         var frametime = 0;

           const anim = new Konva.Animation(function(frame) {
             if (State == 1) {
               if (Start_button == true) {
                 Engine_torque = 128;
                 State = 2;
               }
             } else if (State == 2) {
               if (Station == 'Amsterdam') {
                 Engine_torque = 0;
                 Breaking_torque = 128;
                 State = 1;
               }
             }

             if (Engine_torque > 0){
                 deltaX = 3
                 //deltaX = deltaX - (1/Breaking_torque);
             } else {
               deltaX = 3
                 //deltaX = Engine_torque * Math.sin((frame.time * 2 * Math.PI) / period) + centerX
                 //deltaX = deltaX - (1/Breaking_torque);
                 }


           currentX = currentX  +deltaX;

           if (currentX >= maxX) {
           //  deltaX = 0;
            //currentX = 0;
            anim.stop();
           }

             train.setX(
               currentX
             );
           }, train.getLayer());

          anim.start();
              this.$store.commit('runSimulation',false);
    }
},

  methods: {
    changeSize(e) {
      // to() is a method of `Konva.Node` instances
      e.target.to({
        scaleX: Math.random() + 0.8,
        scaleY: Math.random() + 0.8,
        duration: 0.2
      });
    }
  },
  mounted() {
    console.log ("demo mounted");
    const demoContainer = document.querySelector(".demoContainer");
    const observer = new ResizeObserver(() => {
      this.configStage.width = demoContainer.offsetWidth;
      this.configStage.height = demoContainer.offsetHeight;

    });
    observer.observe(demoContainer);

    this.isTypeVanNaar = this.$store.state.params.choiceType == "VanNaar"?true:false
    this.isTypeKoe = this.$store.state.params.choiceType == "Koe"?true:false
    this.isTypeTussenstop = this.$store.state.params.choiceType == "Tussenstop"?true:false




  },
  created() {
//    const demoContainer = document.querySelector(".demoContainer");


//    this.configStage.width = demoContainer.offsetWidth;
//    this.configStage.height = demoContainer.offsetHeight;
//    this.configStage.width = 300;
//    this.configStage.height = 500;

    const trainImage = new window.Image();
//    trainImage.src = "http://cdn.onlinewebfonts.com/svg/img_9921.svg";
    trainImage.src = require ('../assets/img_9921.png');
    trainImage.onload = () => {
      // set image only when it is loaded
      this.trainImage = trainImage;
    };
    const imageCow = new window.Image();
//    imageCow.src = "https://upload.wikimedia.org/wikipedia/commons/3/3c/Cowicon.png";
imageCow.src = require ('../assets/Cowicon.png');
imageCow.onload = () => {
      // set image only when it is loaded
      this.imageCow = imageCow;
    };


  }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.demoContainer {
  background-color: lightblue;
  width: 100%;
  height: 100%;

}

</style>
