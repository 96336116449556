<template>
<div>
  <b-row class="mt-2">
    <b-col sm="2">
      <label for="textarea-large">Opdracht:</label>
    </b-col>
      <b-col sm="10">
        <Assignment />
      </b-col>
    </b-row>

    <b-row class="mt-2">
        <b-col sm="2">
          <label for="textarea-large">Bedenk uit welke deeltaken deze opdracht bestaat:</label>
        </b-col>
        <b-col sm="10">
          <b-form-textarea
            id="textarea-large"
            size="lg"
            placeholder=""
          ></b-form-textarea>
        </b-col>
      </b-row>

      <b-row class="mt-2">
    <b-col sm="2">
      <label for="textarea-large">Bedenk voor iedere deeltaak minimaal 2 mogelijke oplossingen:</label>
    </b-col>
    <b-col sm="10">
      <b-form-textarea
        id="textarea-large"
        size="lg"
        placeholder=""
      ></b-form-textarea>
    </b-col>
  </b-row>

  <b-row class="mt-2">
<b-col sm="2">
  <label for="textarea-large">Beschrijf per set mogelijke oplossingen welke je kiest en waarom:</label>
</b-col>
<b-col sm="10">
  <b-form-textarea
    id="textarea-large"
    size="lg"
    placeholder=""
  ></b-form-textarea>
</b-col>
</b-row>

<b-row class="mt-2">
<b-col sm="2">
<label for="textarea-large">Beschrijf hier in pseudocode de toestanden van je robot.</label>
</b-col>
<b-col sm="10">
<b-form-textarea
  id="textarea-large"
  size="lg"
  placeholder=""
></b-form-textarea>
</b-col>
</b-row>

  </div>
</template>

<script >

import Assignment from './Assignment.vue';
export default {
  components: {
    Assignment
  },
  data() {
    return {
        items:[]
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
