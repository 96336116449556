<template>
<div>

<TeamMembers :items="items" />
</div>
</template>

<script >
import TeamMembers from '../components/TeamMembers.vue'


export default {
  components: {
    TeamMembers
  },
  data() {
    return {
items:[{
id: 1,
      name: 'Leerling 1',
      selected: false,
      authorizations_selected:[]
      },
      {
id: 2,
      name: 'Leerling 2',
      selected: false,
      authorizations_selected:[]
    }]
  }
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
