<template>
 <div class ="ChatListPanel" style="height:100px">
   <h4>Berichten</h4>
    <div class="" >
      <div style="position:absolute; width:90%; top:0px, bottom:30px; overflow:auto">
        <div class="">
          <ul class="">
            <li v-for="item in items" :key="item.id">
              <p>
                    {{ item.message }}
              </p>

              <p style="text-align:right; color:#c1c1c1">
                    {{ item.name }}
              </p>

              <p style="text-align:right; color:#c1c1c1">
                {{ item.date }} {{item.time }}
              </p>

            </li>
          </ul>
        </div>
      </div>
      <div style="position:absolute; bottom:0px">
        <input type="text"> <button>verstuur</button>
    </div>
    </div>
  </div>
</template>

<script>

export default {
    props : ['items']
 }
</script>

<style>
.ChatListPanel { text-align: left;}
.ChatListPanel ul {
    list-style-type: none;
    position: relative;
    margin: 0px;
padding: 0;
}
.ChatListPanel ul:before {

}
.ChatListPanel ul > li {
    margin: 10px 0px ;
    }
.ChatListPanel ul > li > p {
    margin:  0;
}
.ChatListPanel ul > li:before {
}
</style>
